<template>
  <BasePageContent>
    <ManufacturerUpdate
      v-if="true"
      tabbed
      :uuid="uuid"
      @onsave="OnSave"
      @oncancel="OnCancel"
      cancel-text="Cancel"
    />
  </BasePageContent>
</template>

<script>
import ManufacturerUpdate from "@/components/ui/manufacturer/ManufacturerUpdate.vue";
export default {
  components: { ManufacturerUpdate },
  props: {
    uuid: {
      type: String,
    },
  },
  setup() {
    const OnSave = () => {};
    const OnCancel = () => {};
    return {
      OnSave,
      OnCancel,
    };
  },
};
</script>